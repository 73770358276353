import React, { useEffect } from 'react'
import FinancialConsulting from '../components/FinancialConsulting/FinancialConsulting'
import favicon from '../assets/favi.png'
import { Helmet } from 'react-helmet'
const FinancialConsultingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []); 
    return (
        <>
            <Helmet>
                <title>Financial Consulting Service in USA | Canada</title>
                <meta name="description" content="Expert financial consulting services in the USA. We provide tailored solutions for businesses to achieve financial success." />
                <meta name="keywords" content="financial consulting, USA, business solutions, tax planning, investment strategies" />
                <meta name="author" content="Financial Consulting Services" />
                <meta property="og:title" content="Financial Consulting Service in USA" />
                <meta property="og:description" content="Expert financial consulting services in the USA. We provide tailored solutions for businesses to achieve financial success." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://accounting.taxhelpseva.online/financial-consulting" />
                <meta property="og:image" content={favicon} />
                <meta property="og:image:alt" content="Financial Consulting Service" />
                <link rel="icon" href={favicon} type="image/x-icon" />
                <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                <link rel="canonical" href="https://accounting.taxhelpseva.online/financial-consulting" />
            </Helmet>
            <h1>Top Financial Consulting Companies  | Financial Consulting Firms  In USA
            </h1>
            <FinancialConsulting />
        </>
    )
}

export default FinancialConsultingPage
