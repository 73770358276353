// src/components/About/index.js
import React from "react";
import styles from "./index.module.scss"; // Correct path to SCSS file

const About = () => {
  return (
    <div className={styles.aboutContainer} id="about">
      <h2>About Accounting Tax Help Seva</h2>
      <p className={styles.description}>
        Welcome to Accounting Tax Help Seva! We are dedicated to providing
        expert tax and accounting services for individuals, small businesses, and
        organizations. Our goal is to make tax filing simple, transparent, and
        affordable, with a focus on helping you achieve financial peace of mind.
      </p>
      <p className={styles.description}>
        Our team of certified accountants and tax professionals is committed to
        offering personalized solutions that fit your unique financial needs. We
        specialize in everything from tax filing and tax planning to bookkeeping
        and business advisory services.
      </p>
      
      <h2>Why Choose Us?</h2>
      <p className={styles.description}>
        We understand that managing finances can be overwhelming. At Accounting
        Tax Help Seva, we are here to guide you through every step, ensuring that
        you save money, comply with tax regulations, and make informed decisions
        about your finances. Here's why our clients choose us:
      </p>
      <ul className={styles.valuesList}>
        <li>Affordable and transparent pricing</li>
        <li>Expert advice from certified professionals</li>
        <li>Customized financial solutions for individuals and businesses</li>
        <li>Over 10 years of experience in the accounting industry</li>
        <li>Commitment to customer satisfaction and trust</li>
      </ul>

      <h2>Our Values</h2>
      <ul className={styles.valuesList}>
        <li>Integrity</li>
        <li>Trust</li>
        <li>Customer-Focused Service</li>
        <li>Excellence in Financial Guidance</li>
        <li>Confidentiality and Professionalism</li>
      </ul>
    </div>
  );
};

export default About;
