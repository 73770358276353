import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <p>&copy; 2024 Accounting Simplified. All rights reserved.</p>
            <ul className={styles.socials}>
                <li><a href="#">Facebook</a></li>
                <li><a href="#">Twitter</a></li>
                <li><a href="#">LinkedIn</a></li>
            </ul>
        </footer>
    );
};

export default Footer;
