import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SimpleNavbar from './components/SimpleNavbar/SimpleNavbar';
import Home from './pages/home';
import Marquee from './components/Marqueee/Marquee';
import Footer from './components/Footer/Footer';
import FinancialConsulting from './pages/FinancialConsulting';
import Taxaccouting from './pages/taxaccounting'
import { Helmet } from 'react-helmet';
const App = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'G-JWGP9WSR1Y', {});
        }
    }, [location]);

    useEffect(() => {
        // Start of Tawk.to Script
        const Tawk_API = window.Tawk_API || {};
        const Tawk_LoadStart = new Date();

        const s1 = document.createElement('script');
        const s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/670d16692480f5b4f58d29e5/1ia5hv3ng';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    }, []);

    return (
        <> 
      {/* <Helmet>
                <meta name="google-site-verification" content="PGNadU-P4gkNnjN3pij-uSQftOmdaHVc8yV-gy4QwE8" />
            </Helmet> */}
            <SimpleNavbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/financial-consulting" element={<FinancialConsulting />} />
                <Route path="/account-tax-service" element={<Taxaccouting />} />
            </Routes>
            <Footer />
            {/* <Marquee /> */}
        </>
    );
};

const Main = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default Main;
