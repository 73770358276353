import React, { useEffect } from 'react';
import Accounts from '../components/account/index';
import { Helmet } from 'react-helmet';
import favicon from '../assets/favi.png';
const TaxAccounting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <title>Account Tax Service | Accounting Help Consultancy Services</title>
                <meta name="description" content=" Expert accounting consulting firms offering tailored financial solutions, tax advisory, and consultancy services to streamline your business and ensure compliance." />
                <meta name="keywords" content="accounting, consultancy, services, US, financial advice, business solutions" />
                <meta name="author" content="Accounting.helptaxseva.online" />
                <meta property="og:title" content="Accounting Help Consultancy Service in US" />
                <meta property="og:description" content="Get expert accounting help and consultancy services in the US. Our team provides tailored solutions for your business needs." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://accounting.helptaxseva.online/account-tax-service" />
                <meta property="og:image" content={favicon} />
                <meta property="og:image:alt" content="Account Tax Service" />
                <meta rel="icon" href={favicon} />
                <link rel="icon" href={favicon} type="image/x-icon" />
                <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                <link rel="canonical" href="https://accounting.taxhelpseva.online/account-tax-service" />
                <meta name="google-site-verification" content="puoMLExLg3wLIVZx3rP45q3VTkkZ5n-6DU4UZdqJEgE" />
            </Helmet>
            <Accounts />
        </div>
    );
}

export default TaxAccounting;
