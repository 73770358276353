import React, { useState, useEffect } from 'react';
import styles from './Video.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import c1 from '../../assets/c1.jpeg'; // Corrected 'assests' to 'assets'
import c2 from '../../assets/c2.jpeg';
import c3 from '../../assets/c3.jpeg';
import c4 from '../../assets/c4.jpeg';

const images = [c1, c2, c3, c4];

const PhotoCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            nextImage();
        }, 2000); // Change image every 2 seconds

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
        <section className={styles.carousel}>
            <div className={styles.carouselContainer}>
                {/* Fixed image source access */}
                <img src={images[currentIndex]} alt="Accounting advisory service" className={styles.image} />
                <h1 className={styles.heading}>
                    <a href="tel:+18336778666" style={{ textDecoration: "none", color: "white" }}>
                        Professional Accounting Consultancy Services | Expert Financial Solutions Contact Us 
                        {/* <p className={styles.para}> +1 833 677 8666 </p> */}
                    </a>
                </h1>
                {/* <div className={styles.overlay}>
                    <a className={styles.callToAction} href="tel:+18336778666">
                        Click me to reach
                    </a>
                    <a href="mailto:peterbacker1988@gmail.com" className={styles.contactItem}>
                        <FontAwesomeIcon icon={faEnvelope} /> Email Us
                    </a>
                    <a href="tel:+18336778666" className={styles.contactItem}>
                        <FontAwesomeIcon icon={faPhone} /> Call Us
                    </a>
                </div> */}
            </div>
        </section>
    );
};

export default PhotoCarousel;
