import React from 'react';
import styles from './Testimonials.module.scss';

const Testimonials = () => {
    return (
        <>
        <section className={styles.testimonials}>
            <h2>What Our Clients Say</h2>
            <blockquote>
                <p>"This accounting service has transformed our financial management!"</p>
                {/* <cite>- Jane Doe, CEO of TechCorp</cite> */}
            </blockquote>
            <blockquote>
                <p>"Professional and reliable. Highly recommend!"</p>
                {/* <cite>- John Smith, Freelancer</cite> */}
            </blockquote>
        </section>
        Disclaimer: All product names, logos, and brands are property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, logos, and brands does not imply any affiliation or endorsement, unless specified otherwise.
        </>
    );
};

export default Testimonials;
