import React from 'react';
import styles from './Features.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faFileInvoice, faCloud, faDollarSign, faClipboardList, faLock } from '@fortawesome/free-solid-svg-icons';

const featuresData = [
    {
        title: 'Real-Time Reporting',
        description: 'Access up-to-date financial data at your fingertips.',
        icon: faChartBar
    },
    {
        title: 'Automated Invoicing',
        description: 'Save time with our seamless invoicing system.',
        icon: faFileInvoice
    },
    {
        title: 'Cloud Integration',
        description: 'Access your financials anywhere, anytime.',
        icon: faCloud
    },
    {
        title: 'Expense Tracking',
        description: 'Track and categorize expenses effortlessly.',
        icon: faClipboardList
    },
    {
        title: 'Multi-Currency Support',
        description: 'Handle transactions in various currencies seamlessly.',
        icon: faDollarSign
    },
    {
        title: 'Data Security',
        description: 'Your data is protected with top-notch security protocols.',
        icon: faLock
    }
];

const Features = () => {
    return (
        <section className={styles.features} id="features">
            <h2>Features</h2>
            <div className={styles.featureList}>
                {featuresData.map((feature, index) => (
                    <div className={styles.feature} key={index}>
                        <div className={styles.iconContainer}>
                            <FontAwesomeIcon icon={feature.icon} size="3x" />
                        </div>
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Features;
