import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Video from '../components/Video/Video';
import Features from '../components/Features/Features';
import Services from '../components/Services/Services';
import Testimonials from '../components/Testinomials/Testionomial';
import '../styles/index.module.scss';
// import MyForm from '../Form/Form';
import favicon from '../assets/favi.png';
// import Accounts from '../components/account/index'
import Contact from '../components/Contact';
import About from '../components/About/About';
const App = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []); 
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8BMTRW636R";
        script1.async = true;
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8BMTRW636R');
        `;
        document.head.appendChild(script2);

        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);

    return (
        <div className="App">
            <Helmet>
                <title>Accounting Help Consultancy Service in US</title>
                <meta name="description" content=" Expert accounting consulting firms offering tailored financial solutions, tax advisory, and consultancy services to streamline your business and ensure compliance." />
                <meta name="keywords" content="accounting, consultancy, services, US, financial advice, business solutions" />
                <meta name="author" content="Accounting.helptaxseva.online" />
                <meta property="og:title" content="Accounting Help Consultancy Service in US" />
                <meta property="og:description" content="Get expert accounting help and consultancy services in the US. Our team provides tailored solutions for your business needs." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://accounting.helptaxseva.online" />
                <meta property="og:image" content={favicon} />
                <meta property="og:image:alt" content="Accounting Help Consultancy" />
                <meta rel="icon" href={favicon} />
                <link rel="icon" href={favicon} type="image/x-icon" />
                <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                <link rel="canonical" href="https://accounting.taxhelpseva.online/" />
                {/* <meta name="google-site-verification" content="PGNadU-P4gkNnjN3pij-uSQftOmdaHVc8yV-gy4QwE8" /> */}
                
            </Helmet>
            {/*  */}
            <Video />
            {/* <h2 style={{ textAlign: 'center' }}>Accounting Help Consultancy Service in US</h2> */}
            <Features />
            <Services />
            {/* <Accounts /> */}
            <About/>
            <Contact/>
            <Testimonials />
            {/* <MyForm /> */}
        </div>
    );
};

export default App;
