import React, { useState } from 'react';
import styles from './SimpleNavbar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo-removebg-preview.png'
import { Link } from 'react-router-dom';

const SimpleNavbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    return (
        <nav className={styles.simpleNavbar}>
            <div className={styles.logo}>
                <Link to={"/"}>
                <img src={logo} alt="logo" className={styles.logo} />
                </Link>
            </div>
            <div className={styles.head}> </div>
            <div className={styles.hamburger} onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>

            <ul className={`${styles.navLinks} ${isMenuOpen ? styles.active : ''}`}>
                <li><Link to="/">Home</Link></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    );
};

export default SimpleNavbar;
