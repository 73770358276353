import React from 'react';
import styles from './Services.module.scss';
import c1 from '../../assets/c1.jpeg'; // Corrected 'assests' to 'assets'
import c2 from '../../assets/c2.jpeg';
import c3 from '../../assets/c3.jpeg';
import c4 from '../../assets/c4.jpeg';
import { Link } from 'react-router-dom';
const servicesData = [
    { title: 'Account Tax Service', description: 'Focuses on preparing and filing tax returns, ensuring compliance with tax laws, and planning strategies to minimise tax liabilities.', image: c1, alt: "Financial consulting service in US" ,href:"account-tax-service"},
    { title: 'Bookkeeping', description: 'Maintain accurate financial records with ease.', image: c2, alt: "Tax advisory service" },
    { title: 'Financial Consulting', description: 'Strategic advice to improve your financial performance.', image: c4, alt: "Audit consultancy service US", href: "financial-consulting" },
    { title: 'Payroll Services', description: 'Efficient payroll management and compliance.', image: c2, alt: "Financial management consultancy US" },
    { title: 'Business Advisory', description: 'Guidance to drive business growth and success.', image: c1, alt: "Bookkeeping advisory" },
    { title: 'Fraud Prevention', description: 'Strategies to safeguard your financial assets.', image: c3, alt: "Accounting support service US" },
    { title: 'Investment Planning', description: 'Maximize your returns with tailored strategies.', image: c2, alt: "Financial consulting service in US" },
    { title: 'Retirement Planning', description: 'Plan for a secure and comfortable retirement.', image: c4, alt: "Audit consultancy service US " }
];

const Services = () => {
    return (
        <section className={styles.services} id="services">
            <h2>Our Services</h2>
            <p>We offer comprehensive accounting services tailored to your needs.</p>
            <div className={styles.serviceList}>
                {servicesData.map((service, index) => (
                    <div className={styles.service} key={index}>
                        <Link to={service.href} style={{ textDecoration: "none" }}>
                            <img src={service.image} alt={service.title} />
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Services;
